import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import WeCreateSection from './WeCreateSection';
import axios from 'axios';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const LastLineStyle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 300px;

  .company-info-wrapper {
    width: 153px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    color: #fff;
  }
`;

const fillAnimation = keyframes`
    0% {
        background-position: 100% 50%;
        color: transparent;
    }
    100% {
        background-position: 0% 50%;
        color: transparent;
    }
`;

// 백그라운드가 왼쪽에서 오른쪽으로 줄어드는 애니메이션
const emptyAnimation = keyframes`
    0% {
        background-position: 0% 50%;
        color: transparent;
    }
    99% {
        bacground-size: 0;
        color: transparent;
    }
    100% {
        background-position: -200% 50%;
        background-size: 0;
        color: #fff;
    }
`;

// 애니메이션을 적용할 Styled Component
const AnimatedDiv = styled.a`
  //width: 200px; // 원하는 크기로 조정하세요
  //height: 50px; // 원하는 크기로 조정하세요
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: 0% 50%;
  animation:
    ${fillAnimation} 0.4s linear forwards,
    ${emptyAnimation} 0.5s linear forwards 0.4s;
`;

const TestAnimated = () => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 700,
    config: { duration: 500 },
  });

  return (
    <animated.div
      style={{
        ...fadeIn,
        marginTop: '100px',
        paddingLeft: '14.3vw',
        display: 'flex',
        gap: '2rem',
        color: '#fff',
        fontSize: '1.2rem',
      }}
    >
      AI 혁신과 함께 시작된 디지털 마케팅의 패러다임 전환 속에서,
      <br />
      실질적 가치를 증명하며 업계를 선도하는 게임체인저로 자리매김 한
      <br />
      생성형 AI 솔루션 파트너 - 스카이인텔리전스의 혁신적인 마케팅 솔루션을
      경험해보세요.
      <br />
      <br />
      Amid the AI-driven transformation of digital marketing, <br />
      SKAI Intelligence emerges as a pioneering force and a proven game-changer.
      <br />
      Experience the power of our generative AI solutions—redefining marketing
      with real, measurable impact.
    </animated.div>
  );
};

const AnimatedLine = () => {
  const revealLine = useSpring({
    from: { backgroundPosition: '100% 0' },
    to: { backgroundPosition: '0% 0' },
    delay: 800,
    config: { duration: 200 },
  });

  return (
    <animated.div
      style={{
        ...revealLine,
        width: '13vw',
        height: '1px',
        marginTop: '4vw',
        background: 'linear-gradient(to left, transparent 50%, #fff 50%)',
        backgroundSize: '200% 100%',
      }}
    ></animated.div>
  );
};

const SloganSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloadVisible, setIsDownloadVisible] = useState(false);
  const [companyNoteUrl, setCompanyNoteUrl] = useState<string>('');

  const fetchItem = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/information/company-note/`
    );
    setCompanyNoteUrl(res.data[0].file);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!isVisible && entry.isIntersecting) {
          setIsVisible(entry.isIntersecting);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    setTimeout(() => setIsDownloadVisible(true), 1100);
    fetchItem();
  }, []);

  return (
    <div
      ref={ref}
      className="section-block"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '80px',
      }}
    >
      {isVisible && (
        <>
          <MaskedContainer>
            <WeCreateSection text={'WE CREATE'} fontSize="7vw" />
          </MaskedContainer>
          <MaskedContainer>
            <div style={{ display: 'flex' }}>
              <WeCreateSection
                text={'HIGHLY EFFICIENT,'}
                addDelay={150}
                fontSize="7vw"
              />
            </div>
          </MaskedContainer>
          <MaskedContainer>
            <div style={{ display: 'flex' }}>
              <WeCreateSection
                text={'CUTTING-EDGE '}
                addDelay={400}
                fontSize="7vw"
              />
              <WeCreateSection
                text={'MARKETING'}
                addDelay={400}
                fontSize="7vw"
              />
            </div>
          </MaskedContainer>
          <MaskedContainer>
            <div style={{ display: 'flex' }}>
              <WeCreateSection
                text={'SOLUTIONS '}
                isSpecial
                addDelay={500}
                fontSize="7vw"
              />
              <WeCreateSection
                text={'POWERED BY AI'}
                addDelay={500}
                fontSize="7vw"
              />
            </div>
          </MaskedContainer>
          {/* <MaskedContainer>
            <div style={{ paddingLeft: '14vw', display: 'flex' }}>
              <WeCreateSection
                text={'EFFICIENT '}
                addDelay={500}
                fontSize="7vw"
              />
              <AnimatedLine />
              <WeCreateSection text={' WAY'} addDelay={500} fontSize="7vw" />
            </div>
          </MaskedContainer> */}
          <LastLineStyle>
            <TestAnimated />
            {isDownloadVisible && (
              <AnimatedDiv
                style={{ textDecoration: 'none', color: '#fff' }}
                href="https://drive.google.com/drive/folders/1l0M8KgQQ58Xiu3iHkFqN3eGcjAt6pMAn?usp=sharing"
                target="_blank"
                className="company-info-wrapper"
                rel="noreferrer"
              >
                소개서 다운로드
              </AnimatedDiv>
            )}
          </LastLineStyle>
        </>
      )}
    </div>
  );
};

export default SloganSection;
