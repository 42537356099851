import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import WeCreateSection from './WeCreateSection';
import useObserverHook from '../../hooks/useObserverHook';

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const AnimatedWrapper = styled.div`
  width: 100vw;
  padding-right: 32px;

  .company-info-wrapper {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 13px;
    margin-top: 20px;
  }
`;

const fillAnimation = keyframes`
    0% {
        background-position: 100% 50%;
        color: transparent;
    }
    100% {
        background-position: 0% 50%;
        color: transparent;
    }
`;

// 백그라운드가 왼쪽에서 오른쪽으로 줄어드는 애니메이션
const emptyAnimation = keyframes`
    0% {
        background-position: 0% 50%;
        color: transparent;
    }
    99% {
        bacground-size: 0;
        color: transparent;
    }
    100% {
        background-position: -200% 50%;
        background-size: 0;
        color: #fff;
    }
`;

// 애니메이션을 적용할 Styled Component
const DownLoad = styled.a`
  //width: 200px; // 원하는 크기로 조정하세요
  //height: 50px; // 원하는 크기로 조정하세요
  background-image: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: 0% 50%;
  animation:
    ${fillAnimation} 0.4s linear forwards,
    ${emptyAnimation} 0.5s linear forwards 0.4s;
`;

const AnimatedDiv = () => {
  const [isVisible, setIsVisible] = useState(false);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 2000, // 6초 후에 애니메이션 시작
    config: { duration: 500 }, // opacity가 0에서 1로 변경되는 데 걸리는 시간 (예: 1초)
  });

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 2100);
  }, []);

  return (
    <animated.div
      style={{
        ...fadeIn,
        marginTop: '60px',
        marginLeft: '44px',
        paddingRight: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        color: '#fff',
        wordBreak: 'keep-all',
      }}
    >
      AI 혁신과 함께 시작된 디지털 마케팅의 패러다임 전환 속에서, 실질적 가치를
      증명하며 업계를 선도하는 게임체인저로 자리매김 한 생성형 AI 솔루션 파트너
      - 스카이인텔리전스의 혁신적인 마케팅 솔루션을 경험해보세요.
      <br />
      <br />
      Amid the AI-driven transformation of digital marketing, SKAI Intelligence
      emerges as a pioneering force and a proven game-changer. Experience the
      power of our generative AI solutions—redefining marketing with real,
      measurable impact.
      {isVisible && (
        <DownLoad
          style={{ textDecoration: 'none', color: '#fff' }}
          href="https://drive.google.com/drive/folders/1l0M8KgQQ58Xiu3iHkFqN3eGcjAt6pMAn?usp=sharing"
          target="_blank"
          className="company-info-wrapper"
          rel="noreferrer"
        >
          소개서 다운로드
        </DownLoad>
      )}
    </animated.div>
  );
};

const AnimatedLine = () => {
  const revealLine = useSpring({
    from: { backgroundPosition: '100% 0' },
    to: { backgroundPosition: '0% 0' },
    delay: 2800, // 4초 후에 애니메이션 시작
    config: { duration: 200 }, // 애니메이션이 진행되는 시간 (2초)
  });

  return (
    <animated.div
      style={{
        ...revealLine,
        width: '13vw',
        height: '1px',
        marginTop: '4vw',
        background: 'linear-gradient(to left, transparent 50%, #fff 50%)',
        backgroundSize: '200% 100%',
      }}
    ></animated.div>
  );
};

const SloganSectionMo = () => {
  const { ref, isVisible } = useObserverHook();

  return (
    <div ref={ref} className="section-block">
      {isVisible && (
        <AnimatedWrapper>
          <MaskedContainer>
            <WeCreateSection text={'WE CREATE'} fontSize="50px" />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection text={'HIGHLY'} addDelay={250} fontSize="50px" />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection
              text={'EFFICIENT,'}
              addDelay={250}
              fontSize="50px"
            />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection
              text={'CUTTING-EDGE'}
              addDelay={400}
              fontSize="50px"
            />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection
              text={'MARKETING'}
              addDelay={500}
              fontSize="50px"
            />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection
              text={'SOLUTIONS'}
              isSpecial
              addDelay={500}
              fontSize="50px"
            />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection text={'POWERED'} addDelay={600} fontSize="50px" />
          </MaskedContainer>
          <MaskedContainer>
            <WeCreateSection text={'BY AI'} addDelay={600} fontSize="50px" />
          </MaskedContainer>
          <AnimatedDiv />
        </AnimatedWrapper>
      )}
    </div>
  );
};

export default SloganSectionMo;
