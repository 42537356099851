import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ListChildComponentProps } from 'react-window';
import Slider from '../about/Slider';

const textLoop = keyframes`
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(var(--total-move), 0, 0);
    }
`;

const ContainerStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;

  //.flow-texts {
  //  display: flex;
  //  flex: 0 0 auto;
  //  white-space: nowrap;
  //  overflow: hidden;
  //  transition: 0.5s;
  //  gap: 12vw;
  //}

  .company-name-wrapper {
    color: white;
    display: flex;
    gap: 10vw;
    font-size: 400px;
    font-weight: 700;
    margin-right: 260px;

    & > div:last-child {
      color: #000;
      text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
  }
`;

const CompanyNameWrapper: React.FC<{ text1: string; text2: string }> =
  React.memo(({ text1, text2 }) => {
    return (
      <div className="company-name-wrapper">
        <div>{text1}</div>
        <div>{text2}</div>
      </div>
    );
  });

CompanyNameWrapper.displayName = 'CompanyNameWrapper';

const ListWrapper = styled(List)`
  & > div {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    gap: 10vw;
  }
`;

const CompanyNameSection: React.FC = () => {
  const itemCount = 1000;
  const [items, setItems] = useState<number[]>(
    Array.from(Array(itemCount).keys())
  );

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newItems = items.concat(
          Array.from(
            { length: stopIndex - startIndex + 1 },
            (_, index) => startIndex + index
          )
        );
        setItems(newItems);
        resolve();
      }, 1000); // Simulate network request delay
    });
  };

  const isItemLoaded = (index: number): boolean => index < items.length;

  const Item: React.FC<ListChildComponentProps> = ({ index, style }) => (
    <CompanyNameWrapper text1="SKAI" text2="INTELLIGENCE" />
  );

  // useEffect(() => {
  //   const companyNameWrapper = document.querySelector(
  //     '.company-name-wrapper'
  //   ) as HTMLElement | null;
  //
  //   if (companyNameWrapper) {
  //     const rect = companyNameWrapper.getBoundingClientRect();
  //     console.log(rect.width);
  //     const width = (rect.width / window.innerWidth) * 100000;
  //     const totalWidth = width + 1.4881 * 2;
  //
  //     document.documentElement.style.setProperty(
  //       '--total-move',
  //       `-${totalWidth}vw`
  //     );
  //   }
  // }, []);

  return (
    <ContainerStyle className="section-block">
      <Slider speed={100}>
        <CompanyNameWrapper text1="SKAI" text2="INTELLIGENCE" />
        <CompanyNameWrapper text1="SKAI" text2="INTELLIGENCE" />
        <CompanyNameWrapper text1="SKAI" text2="INTELLIGENCE" />
      </Slider>
      {/*<div className="flow-texts">*/}
      {/*  <InfiniteLoader*/}
      {/*    isItemLoaded={isItemLoaded}*/}
      {/*    itemCount={itemCount}*/}
      {/*    loadMoreItems={loadMoreItems}*/}
      {/*  >*/}
      {/*    {({ onItemsRendered, ref }) => (*/}
      {/*      <ListWrapper*/}
      {/*        style={{ overflowX: 'hidden' }}*/}
      {/*        height={window.innerHeight * 0.5}*/}
      {/*        width={window.innerWidth}*/}
      {/*        itemCount={items.length}*/}
      {/*        itemSize={20}*/}
      {/*        onItemsRendered={onItemsRendered}*/}
      {/*        layout="horizontal"*/}
      {/*        ref={ref}*/}
      {/*      >*/}
      {/*        {Item}*/}
      {/*      </ListWrapper>*/}
      {/*    )}*/}
      {/*  </InfiniteLoader>*/}
      {/*</div>*/}
    </ContainerStyle>
  );
};

export default CompanyNameSection;
