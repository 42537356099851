import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as Sales } from '../../assets/images/Sales.svg';
import { ReactComponent as Save } from '../../assets/images/Save.svg';
import { ReactComponent as Style } from '../../assets/images/Style.svg';
import { useSpring, animated, easings } from 'react-spring';
import ProgressiveImage from '../ProgressiveImage';
import useObserverHook, {
  useAlwaysObserverHook,
} from '../../hooks/useObserverHook';
import WeCreateSection from './WeCreateSection';

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
`;

const fadeOut = keyframes`
    to {
        opacity: 0;
        visibility: hidden;
    }
`;

const MaskedContainer = styled.div`
  mask-image: linear-gradient(to bottom, transparent, black 0, black 0);
  overflow: hidden;
`;

const ContainerStyle = styled.div<{ hovered: string }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .dressing-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    pointer-events: none;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      animation: ${fadeIn} 1s ease-in-out;
      opacity: 0.5;
    }
  }

  .saas-head {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    left: 14.3vw;
    top: 4vh;
    z-index: 10;

    .slash-text {
      font-size: 180px;
      font-weight: 500;
      color: #fff;
      margin-right: 20px;
      line-height: 1;
    }

    .saas-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 6px;

      .saas-title-text {
        font-size: 90px;
        font-weight: 700;
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 1;
      }
    }

    .saas-head-desc {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      //margin-left: 10vw;
      padding-bottom: 10px;
      font-size: 1.2rem;
      position: absolute;
      bottom: 0;
      left: calc(100vw / 2);
    }
  }

  .dressing-body {
    padding-top: 420px;
    line-height: 1;
    display: flex;
    justify-content: center;
    gap: 160px;
  }

  .fade-out {
    animation: ${fadeOut} 1s ease-in-out;
  }
`;

const SectionStyle = styled.div`
  height: 100%;
  display: flex;
  gap: 80px;
  z-index: 10;

  .dressing-body-left {
    color: #fff;
    font-size: 160px;
  }

  .dressing-body-right {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .dressing-right-num {
      color: #fff;
      font-size: 64px;
    }

    .dressing-right-body {
      color: #fff;
      display: flex;
      gap: 40px;

      .dressing-desc-left {
        & > svg {
          transform: rotate(45deg);
        }
      }

      .dressing-desc-right {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .dressing-desc-en {
          line-height: 1.2;
        }

        .dressing-desc-kr {
          line-height: 1.2;
        }
      }
    }
  }
`;

const SectionComponent = ({ name, children, hovered }) => {
  const props = useSpring({
    opacity: hovered === name || hovered === '' ? 1 : 0.2,
    transform: hovered === name ? 'scale(1.2)' : 'scale(1)',
    config: { duration: 500, easing: easings.easeInOutCubic },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

const images = ['images/sales.png', 'images/style.png', 'images/save.png'];

function getImageIndex(hovered: string) {
  if (hovered === 'left') {
    return 0;
  } else if (hovered === 'center') {
    return 1;
  } else if (hovered === 'right') {
    return 2;
  } else {
    return -1;
  }
}

const AigcSaasSection = () => {
  const [hovered, setHovered] = useState('');
  const [imageIndex, setImageIndex] = useState(1);
  const { ref, isVisible } = useObserverHook();
  const { ref: alwaysRef, isVisible: alwaysVisible } = useAlwaysObserverHook();

  useEffect(() => {
    setImageIndex(getImageIndex(hovered));
  }, [hovered]);

  useEffect(() => {
    if (!alwaysVisible) {
      setHovered('');
    }
  }, [alwaysVisible]);

  return (
    <ContainerStyle hovered={hovered} ref={ref}>
      <div className="dressing-bg">
        {hovered !== '' && (
          // <ProgressiveImage
          //   src={images[imageIndex]}
          //   width={window.innerWidth * 0.9}
          //   height={window.innerHeight * 0.85}
          //   trigger={hovered !== ''}
          // />
          <img src={images[imageIndex]} alt={images[imageIndex]} />
        )}
      </div>
      <div className="saas-head" ref={alwaysRef}>
        <div className="slash-text">/</div>
        <div className="saas-title-wrapper">
          {isVisible && (
            <>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="AI"
                    fontSize="90px"
                    addDelay={500}
                    notBold
                  />
                </MaskedContainer>
              </div>
              <div className="saas-title-text">
                <MaskedContainer>
                  <WeCreateSection
                    text="DRESSING"
                    fontSize="90px"
                    addDelay={800}
                    notBold
                  />
                </MaskedContainer>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="saas-head-desc">
            AI로 촬영 없이 모델-포즈-배경을
            <br />
            만들어 드립니다
            <br />
            <br />
            We create models, poses, and backgrounds with
            <br />
            AI—no photography needed.
          </div>
        </div>
      </div>
      <div className="dressing-body">
        <SectionComponent name="left" hovered={hovered}>
          <SectionStyle
            onMouseEnter={() => {
              setHovered('left');
            }}
            // onMouseLeave={() => {
            //   setHovered('');
            // }}
          >
            <div className="dressing-body-left left">
              <Sales />
            </div>
            <div className="dressing-body-right left">
              <div className="dressing-right-num">01</div>
              <div className="dressing-right-body">
                <div className="dressing-desc-left">
                  <ArrowUp />
                </div>
                <div className="dressing-desc-right">
                  <div className="dressing-desc-en">
                    Model-worn images boost
                    <br />
                    sales by 40% over
                    <br />
                    background-removed
                    <br />
                    product photos.
                  </div>
                  <div className="dressing-desc-kr">
                    옷 이미지를 배경제거가 아닌,
                    <br /> 모델 착장컷으로만 바꿔도
                    <br /> 판매율은 40% UP
                  </div>
                </div>
              </div>
            </div>
          </SectionStyle>
        </SectionComponent>
        <SectionComponent name="center" hovered={hovered}>
          <SectionStyle
            onMouseEnter={() => {
              setHovered('center');
            }}
            // onMouseLeave={() => {
            //   setHovered('');
            // }}
          >
            <div className="dressing-body-left center">
              <Style />
            </div>
            <div className="dressing-body-right center">
              <div className="dressing-right-num">02</div>
              <div className="dressing-right-body">
                <div className="dressing-desc-left">
                  <ArrowUp />
                </div>
                <div className="dressing-desc-right">
                  <div className="dressing-desc-en">
                    With just one product
                    <br />
                    photo, AI can generate
                    <br />
                    a styled editorial image
                    <br />
                    with model, pose,
                    <br />
                    and background.
                  </div>
                  <div className="dressing-desc-kr">
                    제품 사진 1장만으로,
                    <br />
                    AI가 모델-포즈-배경까지
                    <br />
                    원하는 스타일로 화보
                    <br />
                    생성
                  </div>
                </div>
              </div>
            </div>
          </SectionStyle>
        </SectionComponent>
        <SectionComponent name="right" hovered={hovered}>
          <SectionStyle
            onMouseEnter={() => {
              setHovered('right');
            }}
            // onMouseLeave={() => {
            //   setHovered('');
            // }}
          >
            <div className="dressing-body-left right">
              <Save />
            </div>
            <div className="dressing-body-right right">
              <div className="dressing-right-num">03</div>
              <div className="dressing-right-body">
                <div className="dressing-desc-left">
                  <ArrowUp />
                </div>
                <div className="dressing-desc-right">
                  <div className="dressing-desc-en">
                    Revolutionizing costly
                    <br />
                    and time-consuming shoots,
                    <br />
                    especially for brands
                    <br />
                    with many SKUs
                    <br />
                    or smaller budgets.
                  </div>
                  <div className="dressing-desc-kr">
                    SKU가 많을 수록, 작은
                    <br />
                    브랜드일수록 부담되는
                    <br />
                    촬영 비용&시간을 혁신
                  </div>
                </div>
              </div>
            </div>
          </SectionStyle>
        </SectionComponent>
      </div>
    </ContainerStyle>
  );
};

export default AigcSaasSection;
