import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Logo from '../navigation/Logo';
import HambergerNavButton from '../navigation/HamburgerNavButton';
import MainNavPanel from '../navigation/MainNavPanel';
import AIGCNavTab from '../navigation/AIGCNavTab';
import PortfolioItem from '../portfolio/PortfolioItem';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import AigcWorkDetail from './AigcWorkDetail';
import AigcWorkDetailMo from './AigcWorkDetailMo';
import { isMobile } from 'react-device-detect';
import process from 'process';
import { ReactComponent as Fold } from '../../assets/images/Ic_Fold.svg';
import { ReactComponent as Spread } from '../../assets/images/Ic_Spread.svg';
import { ReactComponent as TopBotton } from '../../assets/images/Btn_Top_Arrow.svg';
import { ReactComponent as TopBottonMo } from '../../assets/images/Btn_Top_Arrow_mo.svg';
import useSWR from 'swr';
import { useWorkStore } from '../../store/useStore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselNextArrow, CarouselPrevArrow } from '../IR/CarouselArrow';
import CarouselItem from '../IR/CarouselItem';
import IRItem from '../IR/IRItem';

interface Results {
  count: number;
  next: string | null;
  previous: string | null;
  results: News[];
}

interface News {
  title: string;
  published_at: string | null;
  thumbnail_image: string | null;
  carousel_image: string | null;
  link_to: string;
  source_name: string;
  is_top: boolean;
  created_at: string;
}

const ContainerStyle = styled.div<{ mobile: boolean }>`
  width: 100vw;
  overflow-x: hidden;

  .header {
    width: 100vw;
    height: 100px;
    padding: 20px 80px;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 18px 20px;
      > a {
        display: block;
        margin-top: 10px;
      }
    }

    .header-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1580px;
    }
  }

  .title {
    padding: 0 80px;
    display: flex;
    justify-content: center;

    .title-content {
      max-width: 1580px;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    .title-text {
      font-size: 7rem;
      font-family: 'Noway', sans-serif;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 4rem;
        line-height: 1;
        margin-bottom: 5px;
      }
    }
  }

  .carousel-wrapper {
    padding: 20px 80px;
    position: relative;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;

    .carousel-content {
      max-width: 1580px;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 20px;
    }

    .carousel-items {
      height: 400px;
      width: 100%;
      background-color: #000;
      color: #fff;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }

  .works-wrapper {
    padding: 20px 80px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;

    .works-content {
      max-width: 1580px;
      width: 100%;
    }

    @media (max-width: 768px) {
      padding: 20px;
    }
  }

  .works-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .top-btn-wrapper {
    position: fixed;
    bottom: ${(props) => (props.mobile ? '14px' : '36px')};
    right: ${(props) => (props.mobile ? '14px' : '40px')};
    z-index: 100;
    opacity: 0.5;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const IRGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  overflow-x: hidden;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

const fetcher = (url) => axios.get(url).then((res) => res.data);

const IRPage: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [news, setNews] = useState<News[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [preventScroll, setPreventScroll] = useState(false);
  const [lastRefVisible, setLastRefVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: -window.innerHeight, behavior: 'smooth' });
  };

  const fetchItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get<News[]>(
        `${process.env.REACT_APP_API_URL}/api/news/news/`
      );
      if (response.data) {
        console.log(response.data);
        setNews([
          ...response.data.sort((prev, next) =>
            prev.created_at > next.created_at ? -1 : 1
          ),
        ]);
      }
    } catch (error) {
      console.error('Fetching error:', error);
    } finally {
      setLoading(false);
    }
  };

  const observer = useRef<IntersectionObserver>();
  const lastItemRef = useCallback(
    (node: Element | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // setPage((prevPage) => prevPage + 1);
          }
        },
        { rootMargin: '200px' }
      );
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  useEffect(() => {
    // setSelectedTab(current);
    fetchItems();

    const handleScroll = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);

    // 페이지 마운트 시 저장된 스크롤 위치로 이동
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }, 500);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (preventScroll) {
      // 스크롤 이벤트 핸들러
      const preventScroll = (e: WheelEvent | TouchEvent) => {
        e.preventDefault();
      };

      // 스크롤 이벤트 리스너 추가
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });

      return () => {
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        window.removeEventListener('wheel', preventScroll);
        window.removeEventListener('touchmove', preventScroll);
      };
    }
  }, [preventScroll]);

  return (
    <ContainerStyle mobile={isMobile}>
      <div className="header">
        <div className="header-content">
          <Link to="/">
            <Logo
              // fill={isMenuOpen ? '#fff' : '#000'}
              fill={'#000'}
              width={isMobile ? 160 : 220}
            />
          </Link>
          {!isMenuOpen && (
            <HambergerNavButton
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              isBlack
              isRelative
            />
          )}
        </div>
      </div>
      <div className="title">
        <div className="title-content">
          <div className="title-text">NEWS</div>
        </div>
      </div>
      <div className="carousel-wrapper">
        <div className="carousel-content">
          <Slider
            {...settings}
            nextArrow={<CarouselNextArrow />}
            prevArrow={<CarouselPrevArrow />}
          >
            {news &&
              news
                .filter((item) => item.is_top)
                .map((item, index) => (
                  <CarouselItem
                    key={index}
                    image={item.carousel_image ? item.carousel_image : ''}
                    text={item.title}
                    link={item.link_to}
                    date={item.published_at ? item.published_at : ''}
                  />
                ))}
          </Slider>
        </div>
      </div>
      <div className="works-wrapper">
        <div className="works-content">
          <IRGrid>
            {news &&
              news.map((item, index) => (
                <IRItem
                  key={index}
                  image={item.thumbnail_image ? item.thumbnail_image : ''}
                  source={item.source_name}
                  title={item.title}
                  link={item.link_to}
                />
              ))}
          </IRGrid>
        </div>
      </div>
      <div className="top-btn-wrapper">
        <TopBottonMo onClick={() => scrollToTop()} />
      </div>
      {isMenuOpen && (
        <MainNavPanel isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
      )}
    </ContainerStyle>
  );
};

export default IRPage;
