import React from 'react';
import styled from 'styled-components';
import Slider from './Slider';

const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  .company-name {
    color: white;
    display: flex;
    font-size: 200px;
    font-weight: 700;

    & > div {
      padding: 120px 40px;
    }

    & > div:last-child {
      color: #000;
      text-shadow:
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    }
  }
`;

const CompanyNameSectionMo: React.FC = () => {
  return (
    <ContainerStyle>
      <Slider speed={40}>
        <div className="company-name">
          <div>SKAI</div>
          <div>INTELLIGENCE</div>
        </div>
        <div className="company-name">
          <div>SKAI</div>
          <div>INTELLIGENCE</div>
        </div>
        <div className="company-name">
          <div>SKAI</div>
          <div>INTELLIGENCE</div>
        </div>
      </Slider>
    </ContainerStyle>
  );
};

export default CompanyNameSectionMo;
