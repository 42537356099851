import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { ReactComponent as Logo } from '../../assets/images/directors-tech-logo.svg';
import { ReactComponent as Logo } from '../../assets/images/SKAI_INTEELIGENCE_logo_white.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import HamburgerNavButton from '../navigation/HamburgerNavButton';
import MainNavPanel from '../navigation/MainNavPanel';
import CompanyNameSection from '../about/CompanyNameSection';
import SloganSection from '../about/SloganSection';
import LottieAnimation from '../LottieTest';
import contactData from '../../assets/videos/Contact.json';
import productData from '../../assets/videos/Product.json';
import solutionData from '../../assets/videos/Solution.json';
import ClientSection from 'components/about/ClientSection';
import AigcShortFormSection from '../about/AigcShortFormSection';
import AigcSaasSection from '../about/AigcSaasSection';
import AiDressingSection from '../about/AiDressingSection';
import SalesDrivenSection from 'components/about/SalesDrivenSection';
import ContactSection from 'components/about/ContactSection';
import BrandingDrivenSection from '../about/BrandingDrivenSection';
import ContactFormSection from '../about/ContactFormSection';
import { Link } from 'react-router-dom';
import useScrollDirection from '../../hooks/useScrollDirection';
import { useWorkStore } from '../../store/useStore';

const ContainerStyle = styled.div`
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  & svg {
    pointer-events: auto;
  }

  .scroll-section {
    width: 100vw;
    color: #fff;
    display: flex;
    justify-content: center;
    z-index: 100;
    position: relative;
    margin-top: -32px;

    .scroll-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;

      .scroll-btn {
        color: #fff;
        font-size: 10px;
        //cursor: pointer;
      }

      .scroll-height {
        width: 1px;
        height: 240px;
        border-right: 1px solid #fff;
        opacity: 0.16;
        margin-top: 23px;
      }
    }
  }

  .about-fixed {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    pointer-events: none;

    .about-header {
      height: 24px;
      padding: 20px 80px;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 100;
      pointer-events: auto;
    }

    .right-nav {
      width: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      color: #fff;
      font-size: 9px;
      font-weight: 500;
      transform: rotate(90deg);
      transform-origin: top right;
      position: absolute;
      top: calc(100%);
      right: 40px;
      pointer-events: auto;

      & > svg {
        transform: rotate(270deg);
      }

      & > div:nth-child(4) {
        color: #8fff00;
      }

      .right-nav-line {
        height: 1px;
        border-bottom: 1px solid #fff;
        opacity: 0.16;
      }

      .right-nav-line.long {
        width: 288px;
      }

      .right-nav-line.short {
        width: 16px;
      }

      .right-nav-line.medium {
        width: 64px;
      }
    }
  }

  .scroll-menu {
    width: 100vw;
    height: 74px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    color: #fff;
    z-index: 100;

    & > button {
      width: 160px;
      position: absolute;
      bottom: 0;
      right: calc(50% - 80px);
      padding: 24px;
      cursor: pointer;
      outline: none;
      border: none;
      transition: 0.3s;
      font-weight: 500;
      border-radius: 0;
      background-color: transparent;
      color: #fff;
      font-size: 10px;
      pointer-events: auto;
    }

    .fixed-arrow {
      & > svg {
        transform: rotate(45deg);
      }
    }

    .fixed-desc {
      font-weight: 300;
      font-size: 10px;
      padding-left: 80px;
      padding-bottom: 24px;
    }

    .fixed-copyright {
      display: flex;
      align-items: flex-end;
      font-size: 10px;
      font-weight: 300;
      padding-right: 68px;
      padding-bottom: 24px;
      gap: 20px;
    }
  }

  .section-block {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .lottie {
    align-items: flex-end;
  }

  .video-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  .about-footer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Arial', sans-serif;
    padding: 20px 0;

    .about-footer-info {
      display: flex;
      justify-content: center;
      gap: 20px;
      width: 600px;
    }

    .company-name,
    .representative,
    .business-number,
    .address,
    .copyright {
      color: white;
      z-index: 1;
    }
  }
`;

const HoverAre = styled.div`
  width: 30px;
  height: 30px;
  //border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    transform: rotate(-90deg);
  }
`;

const LogoStyle = styled(Logo)`
  width: 220px;

  & > svg {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

const HambergerNavButtonStyle = styled(HamburgerNavButton)`
  z-index: 1000;
`;

const StyledArrowUp = styled(ArrowUp)`
  stroke: #8fff00;
  color: #8fff00;

  & > path {
    stroke: #8fff00;
  }

  &:hover {
    stroke: #8fff00;
    cursor: pointer;
  }
`;

const playerContainerStyle = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
} as React.CSSProperties;

const playerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
  border: 'none',
  padding: '0',
  margin: '0',
  pointerEvents: 'none',
} as React.CSSProperties;

// 모바일 환경용 스타일
const playerStyleMo = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100vh',
  width: 'auto',
  objectFit: 'cover',
  border: 'none',
  padding: '0',
  margin: '0',
} as React.CSSProperties;

function calcWidth() {
  const width = window.innerWidth;
  const resultWidth = width - 80;
  const result = resultWidth / width;
  return `${result * 100}vw`;
}

const NewAbout: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
  const [currentSection, setCurrentSection] = useState(1);

  const scrollDirection = useScrollDirection();

  const { setCurrent } = useWorkStore();

  const scrollToTop = () => {
    const currentScroll = window.scrollY;
    window.scrollBy({
      // scroll to top position
      top: -currentScroll,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setCurrent('all');
  }, []);

  useEffect(() => {
    if (videoRef) {
      videoRef.play().catch((error) => console.log('Auto-play failed'));
    }
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 스크롤 다운 시 섹션 진입 감지
            if (scrollDirection === 'down') {
              switch (entry.target.id) {
                case 'about':
                  setCurrentSection(1);
                  break;
                case 'aigc-product':
                  setCurrentSection(2);
                  break;
                case 'aigc-solution':
                  setCurrentSection(3);
                  break;
                case 'contact':
                  setCurrentSection(4);
                  break;
                default:
                  break;
              }
            }
          } else {
            // 스크롤 업 시 섹션 퇴장 감지
            if (scrollDirection === 'up') {
              switch (entry.target.id) {
                case 'about':
                  setCurrentSection(1);
                  break;
                case 'aigc-product':
                  setCurrentSection(1);
                  break;
                case 'aigc-solution':
                  setCurrentSection(2);
                  break;
                case 'contact':
                  setCurrentSection(3);
                  break;
                default:
                  break;
              }
            }
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }
    );

    // 관찰할 타겟 요소 추가
    const targets = document.querySelectorAll('.section');
    targets.forEach((target) => observer.observe(target));

    // 클린업 함수
    return () => observer.disconnect();
  }, [scrollDirection]);

  return (
    <ContainerStyle>
      <div className="about-fixed">
        <div className="about-header">
          <Link to="/">
            <LogoStyle>
              <Logo />
            </LogoStyle>
          </Link>
          <HambergerNavButtonStyle
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isBlack={false}
          />
        </div>
        <div className="right-nav">
          <div className="right-nav-line long"></div>
          <div>{'SKAI Intelligence'}</div>
          <div className="right-nav-line short"></div>
          <div>{`0${currentSection}`}</div>
          <div className="right-nav-line short"></div>
          <div>ABOUT</div>
          <div className="right-nav-line medium"></div>
          <HoverAre onClick={() => scrollToTop()}>
            <StyledArrowUp />
          </HoverAre>
          <div className="right-nav-line long"></div>
        </div>
      </div>
      <div className="video-container" style={playerContainerStyle}>
        <video
          ref={setVideoRef}
          autoPlay
          loop
          muted
          playsInline
          style={playerStyle}
          src={'videos/about_BG.mp4'}
        />
      </div>
      <div id="about" className="section"></div>
      <SloganSection />
      <div className="scroll-section">
        <div className="scroll-div">
          <div className="scroll-btn">SCROLL DOWN</div>
          <div className="scroll-height"></div>
        </div>
      </div>
      <CompanyNameSection />
      <ClientSection />
      <div id="aigc-product" className="section"></div>
      <div className="section-block lottie">
        <LottieAnimation
          width={calcWidth()}
          margin="0 0 0 80px"
          animationData={productData}
        />
      </div>
      <AigcShortFormSection />
      <AigcSaasSection />
      <AiDressingSection />
      <div id="aigc-solution" className="section"></div>
      <div className="section-block lottie">
        <LottieAnimation
          width={calcWidth()}
          margin="0 0 0 80px"
          animationData={solutionData}
        />
      </div>
      <SalesDrivenSection />
      <BrandingDrivenSection />
      <div id="contact" className="section"></div>
      <div className="section-block lottie">
        <LottieAnimation
          width={calcWidth()}
          margin="0 0 0 80px"
          animationData={contactData}
        />
      </div>
      <ContactFormSection />
      <ContactSection />
      <div className="about-footer">
        <div className="about-footer-info">
          <div className="company-name">(주)스카이인텔리전스</div>
          <div className="representative">대표자: 이재철</div>
          <div className="business-number">사업자등록번호: 294-88-03070</div>
        </div>
        <div className="address">
          주소: 서울 강남구 테헤란로 516 정헌빌딩 4층, 스카이인텔리전스
          (우)06180
        </div>
        <div className="copyright">
          Copyright 2023 SKAI Intelligence all rights reserved
        </div>
      </div>
      <div className="scroll-menu" style={{ pointerEvents: 'none' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="fixed-arrow" style={{ paddingLeft: '80px' }}>
            <StyledArrowUp />
          </div>
          <div className="fixed-desc">
            WE CREATE MARKETING SOLUTIONS
            <br />
            THROUGH A.I IN THE MOST EFFICIENT WAY
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <div className="fixed-arrow" style={{ paddingRight: '80px' }}>
            <StyledArrowUp />
          </div>
          <div className="fixed-copyright">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '4px',
              }}
            >
              <div>© 2024 SKAI Intelligence</div>
              <div
                style={{ display: 'flex', gap: '12px', pointerEvents: 'auto' }}
              >
                <div style={{ color: '#8fff00' }}>Family Site</div>
                <div
                  style={{
                    height: '8px',
                    borderRight: '1px solid #fff',
                    opacity: 0.16,
                  }}
                ></div>
                <a
                  href="http://www.directorscompany.co.kr/"
                  target="_blank"
                  className="popup-link"
                  rel="noreferrer"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  디렉터스컴퍼니
                </a>
                <div
                  style={{
                    height: '8px',
                    borderRight: '1px solid #fff',
                    opacity: 0.16,
                  }}
                ></div>
                <a
                  href="https://www.dweitzer.com"
                  target="_blank"
                  className="popup-link flipper"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    textDecoration: 'none',
                    color: '#fff',
                  }}
                >
                  <div>DA</div>
                  <div
                    className="flip-horizontal"
                    style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
                  >
                    D
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <MainNavPanel isMenuOpen={isMenuOpen} setMenuOpen={setIsMenuOpen} />
      )}
    </ContainerStyle>
  );
};

export default NewAbout;
